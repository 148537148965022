<script setup lang="ts">
import { type TransformedRoute } from '~/utils'
const log = createLog('app:qrhunt:finished')

const props = defineProps<TransformedRoute>()

const { $headerBackTo, $toast, $e } = useNuxtApp()
$headerBackTo({ routeType: 'qrhunt', name: 'overview' } as QRHuntRoute)

const { coins, name, stairs, co2, power, points, trees } = toRefs(props)

onWSMessage<WsError>(ErrorSchema, (data) => {
    const errorMessage = $e(data.content.errorType)
    log.error(errorMessage)
})
</script>

<template>
    <div class="page-route-finished">
        <Icon
            name="check-48x"
            fluid
            circle="#E59EB2"
            class="page-route-finished__media"
        />
        <!-- <img
            class="page-route-finished__media"
            src="~/assets/icons/circle/circle-check-48x.svg?url"
        /> -->

        <RichText
            class="page-route-finished__text rich-text--compact"
            :text="$t('page.routeFinished.introText', { '{routeName}': name })"
        />

        <div class="page-route-finished__scores">
            <h4 class="normal">
                Gutschrift
                <!-- {{ $t('page.profile.accountInfo.headline') }} -->
            </h4>
            <TileGrid>
                <DisplayTile
                    :label="$t('kpiInfo.coinsLabel')"
                    :description="$t('kpiInfo.coinsDescription')"
                    icon="coin-18x"
                    icon-color="#00828C"
                    icon-bg-color="#99cdd1"
                    :value="coins"
                />
                <DisplayTile
                    :label="$t('kpiInfo.scoreLabel')"
                    :description="$t('kpiInfo.scoreDescription')"
                    icon="star-18x"
                    icon-color="#00828C"
                    icon-bg-color="#99cdd1"
                    :value="points"
                />
            </TileGrid>
        </div>

        <div class="page-route-finished__kpis">
            <h4 class="normal">{{ $t('kpiInfo.headline') }}</h4>
            <TileGrid>
                <DisplayTile
                    :label="$t('kpiInfo.stairsLabel')"
                    :description="$t('kpiInfo.stairsDescription')"
                    icon="stairs-18x"
                    icon-color="#CC99CC"
                    icon-bg-color="#ebd6eb"
                    :value="stairs"
                />
                <DisplayTile
                    :label="$t('kpiInfo.powerLabel')"
                    :description="$t('kpiInfo.powerDescription')"
                    icon="power-18x"
                    icon-color="#ea7600"
                    icon-bg-color="#f7c797"
                    unit="Wh"
                    :value="power"
                />
                <DisplayTile
                    :label="$t('kpiInfo.co2Label')"
                    :description="$t('kpiInfo.co2Description')"
                    icon="co2-18x"
                    icon-color="#BF0D3E"
                    icon-bg-color="#e59eb2"
                    :value="co2"
                    unit="g"
                />
                <DisplayTile
                    :label="$t('kpiInfo.treesLabel')"
                    :description="$t('kpiInfo.treesDescription')"
                    icon="leaf-18x"
                    icon-color="#00828C"
                    icon-bg-color="#99cdd1"
                    :value="trees"
                />
            </TileGrid>
        </div>

        <ButtonGroup class="page-route-finished__buttons">
            <AppButton
                fluid
                @click="$qrHuntRouter.push('overview')"
            >
                {{ $t('page.routeFinished.buttonLabel.next') }}
            </AppButton>
        </ButtonGroup>
    </div>
</template>

<style lang="scss">
.page-route-finished {
    height: 100%;
    display: grid;
    grid-template-rows: auto 30px auto 30px auto 18px auto minmax(60px, 1fr) auto;
    grid-template-areas:
        'media  '
        '.      '
        'text   '
        '.      '
        'scores '
        '.      '
        'kpis   '
        '.      '
        'buttons';

    &__media {
        grid-area: media;
        width: min(100%, 80px);
    }

    &__text {
        grid-area: text;
    }

    &__scores {
        grid-area: scores;
    }

    &__kpis {
        grid-area: kpis;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>
